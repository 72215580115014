import Grid from "@mui/material/Grid2";
import { CsValueController } from "@plansoft-configuration-renderer/framework";
import {
  AttributeControlProps,
  useThumbnailControlLayoutProps,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import { CustomThumbnailItem } from "./CustomThumbnailItem";

export function CustomThumbnailControl(
  props: AttributeControlProps,
): JSX.Element {
  const values = useVisibleAttributeValues();

  const { gridSpacing, gridDirection, gridBreakpoints } =
    useThumbnailControlLayoutProps();

  return (
    <Grid container direction={gridDirection} spacing={gridSpacing}>
      {values.map((value) => (
        <Grid key={value.id} size={gridBreakpoints}>
          <CsValueController value={value}>
            <CustomThumbnailItem>{props.children}</CustomThumbnailItem>
          </CsValueController>
        </Grid>
      ))}
    </Grid>
  );
}
