import { ReactNode } from "react";
import { Card, styled } from "@mui/material";
import {
  ThumbnailActionArea,
  ThumbnailValueComponentsProvider,
} from "@plansoft-configuration-renderer/components";
import { useLocalOptionSelected } from "@plansoft-configuration-renderer/framework";

const ThumbnailCard = styled(Card)({
  height: "100%",
  display: "flex",
});

ThumbnailCard.displayName = "ThumbnailCard";

export interface ThumbnailItemProps {
  children: ReactNode;
}

/**
 * Renders basic wrapper around the content
 */
export function CustomThumbnailItem(props: ThumbnailItemProps): JSX.Element {
  // there is a isSelected value but it does not work with local values
  const isSelected = useLocalOptionSelected();

  // Note: do not change "elevation" property of Card/Paper since it interferes with "raised"
  return (
    <ThumbnailCard className={isSelected ? "active" : ""}>
      <ThumbnailValueComponentsProvider>
        <ThumbnailActionArea>{props.children}</ThumbnailActionArea>
      </ThumbnailValueComponentsProvider>
    </ThumbnailCard>
  );
}
