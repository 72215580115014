import axios from "axios";

interface IGetCfgProductInformationResult {
  context?: object;
  currencyId?: string;
  id?: string;
  isReadOnly?: boolean;
  localeId?: string;
  objects?: [];
  output?: string;
  properties?: object;
  releaseId?: string;
}

export async function getCfgProductInformation({
  cfgSessionId,
  getterUrl,
  positionPath,
}: {
  cfgSessionId: string;
  getterUrl: string;
  positionPath?: string;
}): Promise<IGetCfgProductInformationResult | null> {
  try {
    const result = await axios.post<IGetCfgProductInformationResult>(
      `/preview/api/3pq-cs/v2/configuration/configurations/${cfgSessionId}/userDefinedGetter/${getterUrl}`,
      { input: positionPath },
    );

    return result.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}

/*export const productInformationQuery = selectorFamily<
  IGetCfgProductInformationResult,
  {
    cfgSessionId: string | undefined;
    getterUrl: string | undefined;
  }
>({
  key: "ProductInformation",
  get:
    ({ cfgSessionId, getterUrl }) =>
    async () => {
      if (cfgSessionId && getterUrl) {
        console.log("getCfgProductInformation");
        return await getCfgProductInformation({ cfgSessionId, getterUrl });
      }

      return null;
    },
});*/
